import PropTypes from 'prop-types';
import React from 'react';

import styles from './StarRatings.module.scss';

const Star = (props) => {
    const { percent, color } = props;

    const linearGradientId = React.useId();

    const isPartial = percent > 0 && percent < 1;
    const isEmpty = percent === 0;

    const stopPercent = Math.ceil(percent * 100);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 15"
            fill="none"
        >
            {isPartial && (
                <defs>
                    <linearGradient id={linearGradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" style={{ stopColor: color.filled, stopOpacity: 1 }} />
                        <stop
                            offset={`${stopPercent}%`}
                            style={{ stopColor: color.filled, stopOpacity: 1 }}
                        />
                        <stop
                            offset={`${stopPercent}%`}
                            style={{ stopColor: color.empty, stopOpacity: 1 }}
                        />
                        <stop offset="100%" style={{ stopColor: color.empty, stopOpacity: 1 }} />
                    </linearGradient>
                </defs>
            )}
            <path
                d="M7.812.522a.2.2 0 0 1 .376 0l1.694 4.697a.2.2 0 0 0 .182.132l4.99.16a.2.2 0 0 1 .116.357L11.227 8.93a.2.2 0 0 0-.07.214l1.39 4.796a.2.2 0 0 1-.304.22l-4.13-2.804a.2.2 0 0 0-.225 0l-4.131 2.805a.2.2 0 0 1-.305-.221l1.39-4.796a.2.2 0 0 0-.069-.214L.83 5.868a.2.2 0 0 1 .116-.358l4.99-.16a.2.2 0 0 0 .182-.131L7.812.522Z"
                style={{
                    fill: isPartial
                        ? `url(#${linearGradientId})`
                        : isEmpty
                          ? color.empty
                          : color.filled,
                }}
            />
        </svg>
    );
};

const defaultColor = { empty: '#D0D5DD', filled: '#588301' };

/**
 * This component show a star ratings.
 * It is implemented to render how much stars is needed and fulfill them with the
 * corresponding ratings.
 */
export const StarRatings = (props) => {
    const { total = 5, ratings = 0, color = defaultColor } = props;

    const starsProps = React.useMemo(() => {
        let ratingsRest = ratings;

        return [...Array(total)].reduce((acc, cur) => {
            const y = ratingsRest - 1;

            ratingsRest = ratingsRest - 1;

            if (y >= 0) {
                acc.push({ percent: 1 });
                return acc;
            }

            if (y <= -1) {
                acc.push({ percent: 0 });
                return acc;
            }

            acc.push({ percent: y + 1 });

            return acc;
        }, []);
    }, [total, ratings]);

    return (
        <div title={`${ratings} of ${total}`} className={styles[`star-ratings`]}>
            {starsProps.map((starProps, i) => (
                <Star key={i} {...starProps} color={color} />
            ))}
        </div>
    );
};

StarRatings.propTypes = {
    /**
     * Total number of starts
     */
    total: PropTypes.number,
    /**
     * Ratings
     */
    ratings: PropTypes.number,
    /**
     * Star colors
     */
    color: PropTypes.shape({
        empty: PropTypes.string,
        filled: PropTypes.string,
    }),
};
