const TEN_MINUTES = 10 * 60;
const ONE_HOUR = 60 * 60;
const TEN_HOUR = 10 * 60 * 60;
/**
 * Convert seconds to a string MM:SS or HH:MM:SS
 * @param {number} seconds
 * @param {(short|long|descriptive)} [formatType=short]
 * @param {boolean} [trimSeconds=false] - If true, seconds will be omitted
 * @returns {string}
 */
export function secondsToHoursMinutesSeconds(seconds, formatType = 'short', trimSeconds = false) {
    if (seconds == null) {
        throw new Error(`'seconds' is required`);
    }

    if (!['short', 'long', 'descriptive'].includes(formatType)) {
        throw new Error('Invalid format type');
    }

    if (formatType === 'short' && seconds < TEN_MINUTES) {
        return new Date(seconds * 1000).toISOString().substring(15, 19);
    }

    if (formatType === 'short' && seconds < ONE_HOUR) {
        return new Date(seconds * 1000).toISOString().substring(14, 19);
    }

    if (formatType === 'short' && seconds < TEN_HOUR) {
        return new Date(seconds * 1000).toISOString().substring(12, 19);
    }

    if (formatType === 'long' || (formatType === 'short' && seconds >= TEN_HOUR)) {
        return new Date(seconds * 1000).toISOString().substring(11, 19);
    }

    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let secs = seconds % 60;

    let result = '';
    if (hours > 0) {
        result += hours + (hours === 1 ? ' hour' : ' hours');
    }

    if (minutes > 0) {
        if (result !== '') {
            result += ', ';
        }
        result += minutes + (minutes === 1 ? ' min' : ' mins');
    }

    if (secs > 0 && !trimSeconds) {
        if (result !== '') {
            result += ', ';
        }
        result += secs + (secs === 1 ? ' sec' : ' secs');
    }

    if (result === '' && !trimSeconds) {
        result = '0 secs';
    }

    return result;
}
