import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { Button } from '@/design-system/atoms/Button';

import styles from './ButtonGroup.module.scss';

const normalizeButtonsFn = (buttons) => {
    if (!buttons || !Array.isArray(buttons) || buttons.length === 0) {
        return [];
    }

    if (buttons.length === 1) {
        const button = buttons[0];
        button.buttonStyle = button.buttonStyle ?? Button.STYLE.PRIMARY;
        return [button];
    }

    const newButtons = [];
    const [firstButton, ...otherButtons] = buttons;

    newButtons.push({
        ...firstButton,
        buttonStyle: Button.STYLE.PRIMARY,
    });

    otherButtons.forEach((button) => {
        newButtons.push({
            ...button,
            buttonStyle: Button.STYLE.SECONDARY,
        });
    });

    return newButtons;
};

const ButtonGroup = ({
    buttons,
    normalizedButtons: normalizedButtonsProp = false,
    className,
    fullWidth,
    analytics,
    secondButtonAnalytics,
    ...props
}) => {
    const normalizedButtons = React.useMemo(
        () => (normalizedButtonsProp ? normalizeButtonsFn(buttons) : buttons),
        [buttons, normalizedButtonsProp],
    );

    return (
        <>
            {Array.isArray(normalizedButtons) && normalizedButtons.length !== 0 && (
                <div className={classnames(styles.buttonGroup, className)} {...props}>
                    {normalizedButtons[0] && (
                        <Button
                            {...normalizedButtons[0]}
                            buttonStyle={normalizedButtons[0].buttonStyle ?? Button.STYLE.PRIMARY}
                            fullWidth={fullWidth}
                            analytics={analytics}
                        />
                    )}
                    {normalizedButtons[1] && (
                        <Button
                            {...normalizedButtons[1]}
                            buttonStyle={normalizedButtons[1].buttonStyle ?? Button.STYLE.SECONDARY}
                            fullWidth={fullWidth}
                            analytics={{ ...analytics, ...secondButtonAnalytics }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

ButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
    fullWidth: PropTypes.shape({
        sm: PropTypes.bool,
        md: PropTypes.bool,
        lg: PropTypes.bool,
    }),
    normalizedButtons: PropTypes.bool,
    analytics: customPropTypes.analyticsPropType,
    secondButtonAalytics: customPropTypes.analyticsPropType,
};

export default ButtonGroup;
