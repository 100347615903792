import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Breadcrumb, Breadcrumbs, Link as RacLink } from 'react-aria-components';
import { Link as RouterLink } from 'react-router-dom';

import * as customPropTypes from '@/custom-prop-types';
import { IconArrowLeft } from '@/design-system/atoms/Icons/IconArrowLeft';
import { IconArrowRight } from '@/design-system/atoms/Icons/IconArrowRight';
import { IconHome } from '@/design-system/atoms/Icons/IconHome';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { trackInteraction } from '@/utils/analytics';

import styles from './Breadcrumb.module.scss';

/**
 * The Breadcrumb component serve as an effective visual aid, indicating the location of the
 * user within the website's hierarchy, making them a great source of contextual information
 * for landing pages. Also, breadcrumbs allow for easy navigation to higher-level pages.
 *
 * Usage:
 * - Default Usage: Employs default Design System values for the breadcrumb items.
 * - Custom Usage: Allows defining a custom number of breadcrumb items using the 'items' prop.
 */
const BreadcrumbComponent = ({
    items, // Array of items to be rendered as breadcrumbs
    className,
    analytics,
    ...props
}) => {
    const breakpoints = useBreakpoint();

    // Calculate the number of breadcrumbs to show based on the breakpoint

    const visibleBreadcrumbs = items.length > 2 ? [items[items.length - 2]] : [];

    // click handler
    const handleClickEvent = React.useCallback(
        (linkText) => {
            if (analytics) {
                trackInteraction({
                    actionLabel: linkText,
                    interactionType: 'breadcrumb',
                    selector: 'breadcrumb',
                    ...analytics,
                });
            }
        },
        [analytics],
    );

    return !breakpoints?.large ? (
        visibleBreadcrumbs.length === 0 ? (
            <></>
        ) : (
            <Breadcrumbs {...props} className={classNames(styles.breadcrumb, className)}>
                {visibleBreadcrumbs.map((item, index) => {
                    const Link = item.csr ? RouterLink : RacLink;
                    return (
                        <Breadcrumb
                            key={index}
                            className={classNames(styles.breadcrumb__item, 'type-cta3')}
                        >
                            <Link
                                href={item.href}
                                to={item.csr ? item.href : undefined}
                                className={classNames(styles.breadcrumb__link, 'type-cta3')}
                                onClick={() => {
                                    handleClickEvent(item.title);
                                }}
                                data-trigger={
                                    analytics ? analytics?.selector || 'breadcrumb' : undefined
                                }
                            >
                                <div className={styles['breadcrumb__icon-arrow-left']}>
                                    <IconArrowLeft
                                        title="icon-arrow-left"
                                        className={styles['breadcrumb__icon-arrow']}
                                    />
                                </div>
                                {item.title}
                            </Link>
                        </Breadcrumb>
                    );
                })}
            </Breadcrumbs>
        )
    ) : (
        <Breadcrumbs {...props} className={classNames(styles.breadcrumb, className)}>
            {items.map((item, index) => {
                const Link = item.csr ? RouterLink : RacLink;
                return (
                    <Breadcrumb key={index} className={styles.breadcrumb__item}>
                        {index === 0 ? (
                            <>
                                <Link
                                    href={item.href}
                                    to={item.csr ? item.href : undefined}
                                    className={classNames(styles.breadcrumb__link, 'type-cta1')}
                                    aria-label={item.title}
                                    onClick={() => {
                                        handleClickEvent(item.title);
                                    }}
                                    data-trigger={
                                        analytics ? analytics?.selector || 'breadcrumb' : undefined
                                    }
                                >
                                    <IconHome
                                        title="icon-home"
                                        className={styles['breadcrumb__icon-home']}
                                    />
                                </Link>
                            </>
                        ) : (
                            <>
                                <IconArrowRight className={styles['breadcrumb__icon-arrow']} />
                                {index !== items.length - 1 ? (
                                    <Link
                                        href={item.href}
                                        to={item.csr ? item.href : undefined}
                                        className={classNames(styles.breadcrumb__link, 'type-cta1')}
                                        onClick={() => {
                                            handleClickEvent(item.title);
                                        }}
                                        data-trigger={
                                            analytics
                                                ? analytics?.selector || 'breadcrumb'
                                                : undefined
                                        }
                                    >
                                        {item.title}
                                    </Link>
                                ) : (
                                    <span className={'type-cta1'}>{item.title}</span>
                                )}
                            </>
                        )}
                    </Breadcrumb>
                );
            })}
        </Breadcrumbs>
    );
};

BreadcrumbComponent.propTypes = {
    /**
     * Array of items to be rendered as breadcrumbs
     */
    items: PropTypes.arrayOf(
        PropTypes.shape({
            href: PropTypes.string,
            title: PropTypes.string,
            csr: PropTypes.bool,
        }),
    ),
    /**
     * Additional classes to be added to the component
     */
    className: PropTypes.string,
    /**
     * analytics object
     */
    analytics: customPropTypes.analyticsPropType,
};

export default BreadcrumbComponent;
